import { postData } from '@components/_api';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '../context/SnackBarContext';
import { Angebot, AngebotAdresse, Person } from '../interfaces';

type InputType = {
  data: Angebot;
  token: string;
  operation: 'edit' | 'create';
  text?: string;
  isValid?: boolean;
  handleValidateFields: () => Promise<boolean>;
};
export default function SpeichernBtn({
  data,
  token,
  operation,
  text,
  isValid,
  handleValidateFields,
}: InputType) {
  const history = useHistory();
  const { setMessage, setOpen } = useSnackbarContext();

  const createNew = () => {
    // empty angebotePos, otherwise it fails
    const tmp_data = { ...data };
    tmp_data.angebotePos = [];

    postData({
      endpoint: 'AngeboteAuth/createangebot',
      json: tmp_data,
      token,
      apiversion: 'v2',
    }).then((resp) => {
      switch (resp.status) {
        case 200:
          if (data.angebotePos && data.angebotePos.length > 0)
            saveAngebotePos(resp.data.autoLfNr);

          history.push('/mut-atlas');
          setOpen(true);
          setMessage('Vielen Dank! Das Anlegen hat geklappt. Ab morgen wird das Angebot auch im MUT-ATLAS angezeigt.');
          break;
        default:
          setOpen(true);
          setMessage('Ein Fehler ist aufgetreten.');
          break;
      }
    });
  };

  const saveAngebotePos = (autoLfNr: string | undefined) => {
    axios
      .all(
        (data.angebotePos as Person[])?.map(async (p) => {
          const payload = p.angebotAdresse;

          if (typeof payload.fileMakerRecordId === 'undefined') {
            return await postData({
              endpoint: 'AngeboteAuth/createangebotadresse',
              json: payload,
              token,
              apiversion: 'v2',
            }).then((resp) => {
              switch (resp.status) {
                case 200:
                  return resp.data;
                default:
                  setOpen(true);
                  setMessage('Ein Fehler ist aufgetreten.');
                  break;
              }
            });
          } else {
            return payload;
          }
        })
      )
      .then((resp: AngebotAdresse[]) => {
        const newData = { ...data };
        newData.autoLfNr = autoLfNr;
        newData.angebotePos = resp.map((angebotAdresse) => {
          if (angebotAdresse) {
            const funktion =
              data.angebotePos?.find(
                (pos) =>
                  pos?.angebotAdresse?.vorname === angebotAdresse?.vorname
              )?.funktion ?? '';

            return {
              funktion: funktion,
              lfNrAngebote: autoLfNr,
              lfNrAdress: angebotAdresse.lfNrAdresse,
              angebotAdresse: angebotAdresse,
            };
          }
        }) as Person[];

        postData({
          endpoint: 'AngeboteAuth/edituserangebot',
          json: newData,
          token,
          apiversion: 'v2',
        }).then((resp) => {
          switch (resp.status) {
            case 200:
              history.push('/mut-atlas');

              setOpen(true);
              setMessage('Gespeichert. Die Änderungen werden ab morgen auch im MUT-ATLAS angezeigt.');
              break;
            default:
              setOpen(true);
              setMessage('Ein Fehler ist aufgetreten.');
              break;
          }
        });
      });
  };

  /* Speichern */
  const handleClick = async () => {
    const valid = await handleValidateFields();
    // if not valid, do not save
    // valid is checking for validation when user has not touched the fields but wants to save
    // isValid is checking for validation when user has touched the fields
    if (!isValid || !valid) return;
    switch (operation) {
      case 'edit':
        saveAngebotePos(data.autoLfNr);
        break;
      case 'create':
        createNew();
        break;
    }
  };

  return (
    <>
      <Button onClick={() => handleClick()} variant='contained'>
        <SaveAltIcon sx={{ mr: 2 }} />
        {text ?? 'Speichern'}
      </Button>
    </>
  );
}
