import { postData } from '@components/_api';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { useSnackbarContext } from '../../../../../context/SnackBarContext';
import { Angebot } from '../../../../../interfaces';
import { getOrt } from '../../../../../utils/getOrt';
import { error, style } from './CreateTraegerModal_Styles';
import { fieldsConfig } from './fields';

const initFormData = {
  firma: '',
  strasse: '',
  hausnummer: '',
  zusatz: '',
  plz: '',
  ort: '',
  telefon: '',
  email: '',
  homePage: '',
  bemerkungen: '',
  postfachNr: '',
  postfachPlz: '',
};

export type TInitTraegerData = typeof initFormData;

export default function CreateTraegerModal({
  open,
  setOpen,
  setNewTraeger,
  token,
  data,
}: {
  open: boolean;
  setNewTraeger: (lfNrAdresse: string) => void;
  setOpen: (open: boolean) => void;
  token: string;
  data: Angebot;
}) {
  const snackbarctx = useSnackbarContext();

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = async (values: TInitTraegerData) => {
    //data types conversions
    values.plz = values.plz.toString().trim();
    values.postfachPlz = values.postfachPlz.toString();
    values.postfachNr = values.postfachNr.toString();

    const url = values.homePage;
    if (!url.startsWith('http')) values.homePage = 'https://' + url;

    const resp = await postData({
      endpoint: 'AngeboteAuth/createangebottraeger',
      json: values,
      token,
    });

    if (resp.data.firmaNr) setNewTraeger(resp.data.firmaNr);

    switch (resp.status) {
      case 200:
        snackbarctx.setMessage('Gespeichert');
        snackbarctx.setOpen(true);
        setOpen(false);
        break;
      default:
        snackbarctx.setMessage('Ein Fehler ist aufgetreten.');
        snackbarctx.setOpen(true);
        break;
    }
  };

  const validate = (values: TInitTraegerData) => {
    const errors: Partial<TInitTraegerData> = {};
    const {
      email,
      telefon,
      firma,
      hausnummer,
      plz,
      homePage,
      zusatz,
      postfachNr,
      postfachPlz,
    } = values;

    if (!firma) {
      errors.firma = 'Benötigt';
    } else if (!/.+/.test(firma)) {
      errors.firma = 'Ungültiger Träger Name';
    }

    if (!email && !telefon) {
      errors.email = 'Benötigt (oder Telefon)';
    } else if (
      !!email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      errors.email = 'Ungültige E-Mail Adresse';
    }

    if (!telefon && !email) {
      errors.telefon = 'Benötigt (oder E-Mail)';
    } else if (!!telefon && !/^\+*(\d.*){3,}[\s/-]?\d.*/.test(telefon)) {
      errors.telefon = 'Ungültige Telefon Nummer';
    }

    if (!/^[0-9].*/.test(hausnummer)) {
      errors.hausnummer = 'Ungültige Hausnummer';
    }

    if (!/^[0-9]{5}$/.test(plz.trim())) {
      errors.plz = 'Ungültige PLZ: max. 5 Ziffern, keine Buchstaben.';
    }

    if (!/^[0-9]{0,7}$/.test(postfachPlz)) {
      errors.postfachPlz = 'Ungültige PLZ: kein Leerzeichen, max. 5 Ziffern';
    }

    if (!/^[0-9]{0,7}/.test(postfachNr)) {
      errors.postfachNr = 'Ungültige Nummer';
    }

    if (!/^.{0,20}$/.test(zusatz)) {
      errors.zusatz = 'Ungültiger Zusatz, maximale Länge: 20 Zeichen';
    }

    if (
      !/^(http(s):\/\/.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gi.test(
        homePage
      )
    ) {
      errors.homePage = 'Ungültige URL';
    }

    return errors;
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          // timeout: 500,ti
        },
      }}
      classes={{ root: 'modal' }}
    >
      <Box sx={style}>
        <Fade in={open}>
          <div>
            <div>
              <Typography
                variant='h2'
                sx={{
                  marginBottom: '2rem',
                }}
              >
                Träger anlegen
              </Typography>
            </div>
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initFormData}
                validate={validate}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 8, md: 16 }}
                    >
                      {fieldsConfig &&
                        fieldsConfig.map(({ label, name, type }) => {
                          const nameKey = name as keyof TInitTraegerData;
                          return (
                            <Grid item xs={8} pt={0} key={label}>
                              <TextField
                                sx={{
                                  width: '100%',
                                }}
                                InputLabelProps={{ shrink: !!label }}
                                type={type}
                                name={name}
                                id={name}
                                label={label}
                                variant='outlined'
                                onChange={async (e) => {
                                  handleChange(e);

                                  if (
                                    name === 'plz' &&
                                    e.target?.value?.length === 5
                                  ) {
                                    getOrt(e.target.value, setFieldValue);
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values[nameKey]}
                                placeholder={label}
                                InputProps={{
                                  endAdornment:
                                    name === 'strasse' ||
                                    name === 'zusatz' ||
                                    name === 'hausnummer' ||
                                    name === 'plz' ||
                                    name === 'ort' ||
                                    name === 'email' ||
                                    name === 'telefon' ||
                                    name === 'homePage' ? (
                                      data[name] !== '' ? (
                                        <Tooltip
                                          title={`Wert vom Angebot übernehmen`}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              setFieldValue(name, data[name]);
                                              const angebotData = data[name];

                                              if (
                                                name === 'plz' &&
                                                angebotData?.length === 5
                                              ) {
                                                getOrt(
                                                  angebotData,
                                                  setFieldValue
                                                );
                                              }
                                            }}
                                          >
                                            <ContentPasteGoOutlinedIcon fontSize='small' />
                                          </IconButton>
                                        </Tooltip>
                                      ) : null
                                    ) : null,
                                }}
                              />
                              {errors && errors[nameKey] && touched[nameKey] ? (
                                <Box sx={error}>{errors[nameKey]}</Box>
                              ) : null}
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        mt: '2em',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleClose();
                        }}
                        variant='contained'
                        color={'error'}
                      >
                        Cancel
                      </Button>

                      <Button
                        type={'submit'}
                        variant='contained'
                        disabled={Object.keys(errors).length > 0}
                      >
                        <SaveAltIcon sx={{ mr: 2 }} />
                        Speichern
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Fade>
      </Box>
    </Modal>
  );
}
